  /* skeleton css */
  $base-color: #ebebeb;
  $shine-color: #f3f3f3;
  $animation-duration: 1.6s;
  $avatar-offset: 500;

  @mixin background-gradient{
  background-image: linear-gradient(90deg, $base-color 0px, $shine-color 80px, $base-color 160px);
  background-size: 800px;
  }
  
  .skeleton-wrap{  
    position: relative;
    overflow: hidden;
    }
  .skeleton{
    position: absolute;
    left: -1px;
    width: 101%;
    height: 100%;
    z-index: 100000;
    background-color: #ccc;
    @include background-gradient;
    animation: shine-avatar $animation-duration infinite linear;
  }

    @keyframes shine-avatar{
    0%{background-position: -100px +   avatar-offset;}
    40%, 100%{background-position: 200px + $avatar-offset;}
    }
