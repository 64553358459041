// :root {
//   --color-loader: #5E71EC;
//   --loader-size: 50px;
//   --loader-speed: 700ms;
// }

// .loader {
//   --loader-height: calc(var(--loader-size) / 7);
//   display: flex;
  
//   position: relative;
// }
// .loader::before {
//   content: '';
//   position: absolute;
// }
// .loader::before,
// .loader > span {
//   display: block;
//   width: var(--loader-height);
//   height: var(--loader-height);
//   border-radius: 50%;
//   background: var(--color-loader);
  
//   animation-name: to-right;
//   animation-duration: var(--loader-speed);
//   animation-iteration-count: infinite;
//   animation-timing-function: linear;
// }
// .loader > span:nth-child(even) {
//   visibility: hidden;
// }
// .loader > span:first-child {
//   animation-name: scale-up;
// }
// .loader > span:last-child {
//   animation-name: scale-up;
//   animation-direction: reverse;
// }

// @keyframes to-right {
//   to {
//     transform: translateX(200%);
//   }
// }
// @keyframes scale-up {
//   from {
//     transform: scale(0);
//   }
//   to {
//     transform: scale(1);
//   }
// } 

#loader{
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #5E71EC;
  width: 45px;
  height: 45px;
  animation: spin 1s linear infinite;
  margin-top: -30px;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.loading-wrapper {
  // position: fixed;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // margin: auto;
  // z-index: 999999;
}