.aboutus{
  padding-block: 50px;
  .title{
    h1{
      // font-size: 30px;
    }
  }
  @media(max-width:768px) {
    padding-top: 20px;
  }
  .border-radius{
    border-radius: 8px;
  }
  .about-section{
    margin-bottom: 40px;
    @media(max-width:991px) {
        flex-direction: column-reverse;
        margin-bottom: 20px;
        .title{
          margin-top: 30px;
          }
      }  
    }
  .mission-section{
    img{
      margin-bottom: 30px;
      width: 100px;
      height: 80px;
      @media(max-width:991px) {
        margin-bottom: 8px;
      }
    }
    .card{
      display: flex;
      flex-direction: column;
      padding: 30px;
      height: 100%;
      .flex{
        flex-direction: column;
        @media(max-width: 991px){
          flex-direction: row;}
      }
      @media(max-width: 991px){
        padding: 17px;
      }
    }
      @media(max-width: 991px){
        gap: 15px;
      }
  }
  .news-section{
    margin-top: 40px;
    h1{
      text-align: center;
      margin-bottom: 20px;
    }
    @media(max-width: 991px){
      margin-top: 20px;
      h1{
        margin-bottom: 0;
      }
      gap: 10px; 
    }
  }
  .view-btn{
    margin-top: 30px;
    a{ 
      text-decoration: none; 
      cursor: pointer;
      &:hover,&:focus{
        color: #0491A4;
        }
      }
  }
}
.contactus-container{
  padding-block: 30px;
  .row{
  .social{
    img{
      max-width: 24px;
      }
    }
    }
  form{
    input{
      border-radius: 5px;
      }
    button{
      float: right;
      p{ text-transform: uppercase;}
    }
    .field-wrapper{
      margin-bottom: 24px;
      }
    .err_msg{
      margin: 0;
      }
  }
  .message-form{
    .label_txt{
      margin-bottom: 5px;
      }
    }
  .row{
    margin-top: 50px;
    @media(max-width: 991px){
      margin-top: 20px;}
    }
  .branch{
    .title{
      text-transform: uppercase;}
    }
  .address{
    p{
      max-width: 400px;
    }
  }
  @media(max-width: 991px){
    padding-top: 0;
  }
  .phone,.mail{
    a{
      &:not(:last-child)::after{
        content: ',';
        }
      }
    }
}
.generic-page{
  padding-block: 30px;
  .page-title{
    margin-bottom: 30px;
  }
  @media(max-width: 991px){
    padding-top: 0;
    .row{
    flex-direction: column-reverse;
    gap: 20px;
      }
    }
}
.faq-container{
  padding-block: 30px;
  .page-title{
    margin-bottom: 30px;
  }
  @media(max-width: 991px){
    padding-top: 0;
    .row{
      gap: 20px;
      flex-direction: column-reverse;
    }
  }
  .ques-con{
    ul li{
      margin-left: 30px !important;
      // margin-left: 15px !important;
    }
  }
}
